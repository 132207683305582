<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="row">
        <HeaderComp currentPage="chapter"></HeaderComp>

        <LiberiaChapterContent class="text-black" />

        <FooterComp></FooterComp>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import HeaderComp from '@/components/layouts/HeaderComp'
import FooterComp from '@/components/layouts/FooterComp'
import LiberiaChapterContent from '@/views/content/chapters/LiberiaChapterContent'
export default {
  components: { LiberiaChapterContent, FooterComp, HeaderComp },

  data() {
    return {}
  },
}
</script>
