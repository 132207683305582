<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="row">
        <HeaderComp currentPage="chapter"></HeaderComp>

        <MalawiChapterContent class="text-black" />

        <FooterComp></FooterComp>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import HeaderComp from '@/components/layouts/HeaderComp'
import FooterComp from '@/components/layouts/FooterComp'
import MalawiChapterContent from '@/views/content/chapters/MalawiChapterContent'
export default {
  components: { MalawiChapterContent, FooterComp, HeaderComp },

  data() {
    return {}
  },
}
</script>
