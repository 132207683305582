import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/LoginPage.vue'
import RegistrationPage from '../views/RegistrationPage.vue'
import ManagePage from '../views/ManagePage.vue'
import LandingPage from '../views/LandingPage.vue'
import Mission from '../views/MissionPage.vue'
import Vision from '../views/VisionPage.vue'
import Values from '../views/ValuesPage.vue'
import SouthAfrica from '../views/chapters/SouthAfricaChapter.vue'
import Ghana from '../views/chapters/GhanaChapter.vue'
import Zimbabwe from '../views/chapters/ZimbabweChapter.vue'
import Kenya from '../views/chapters/KenyaChapter.vue'
import Lesotho from '../views/chapters/LesothoChapter.vue'
import Liberia from '../views/chapters/LiberiaChapter.vue'
import Malawi from '../views/chapters/MalawiChapter.vue'
import TeamPage from '../views/TeamPage.vue'
import ContactPage from '../views/ContactPage.vue'
import LoginPage from "@/views/LoginPage.vue";
import SharesPage from "@/views/SharesPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/', name: 'Landing', component: LandingPage, alias:['/home']
  },  
  {
    path: '/mission', name: 'Mission', component: Mission
  },
  {
    path: '/vision', name: 'Vision', component: Vision
  },
  {
    path: '/values', name: 'Values', component: Values
  },
  {
    path: '/team', name: 'Team', component: TeamPage
  },
  {
    path: '/chapter/za', name: 'SouthAfrica', component: SouthAfrica
  },
  {
    path: '/chapter/gh', name: 'Ghana', component: Ghana
  },
  {
    path: '/chapter/zim', name: 'Zimbabwe', component: Zimbabwe
  },
  {
    path: '/chapter/kn', name: 'Kenya', component: Kenya
  },
  {
    path: '/chapter/le', name: 'Lesotho', component: Lesotho
  },
  {
    path: '/chapter/lib', name: 'Liberia', component: Liberia
  },
  {
    path: '/chapter/mal', name: 'Malawi', component: Malawi
  },
  {
    path: '/contact', name: 'Contact', component: ContactPage
  },
  {
    path: '/login', name: 'Login', component: LoginPage
  },
  {
    path: '/register', name: 'Register', component: RegistrationPage
  },
  {
    path: '/manage', name: 'Manage', component: ManagePage
  },
  {
    path: '/shares', name: 'Shares', component: SharesPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
