<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="row">
        <HeaderComp currentPage="shares"></HeaderComp>

        <div class="content-wrapper">
          <!-- <div class="comp"> -->
          <RequestSharesComponent class="request"></RequestSharesComponent>
          <SharesPaymentStructureComponent class="payment"></SharesPaymentStructureComponent>
          <!-- </div> -->
          <div class="shares">
            <!-- <AffiliateSearch v-on:searchComplete="handleSearchComplete" />           -->
            <ShareComponent v-if="showResults" v-bind:shares="currentShares"></ShareComponent>

          </div>

        </div>
        <div class="note-wrapper">
          <p class="note"><b>Note:</b>
          <ul>
            <li>Should you subscribe for shares post the expected date of payment, your next payment must include the past
              payment(s). E.g. If buying 100 USD worth of shares
              and you register in June, you are expected to pay 50 USD (30% + 10% + 10% = 50%) by the end of June.
            </li>
            <li>This request is subject to availability of shares and is valid upon approval and
              receipt of payment(s).</li>
          </ul>
          </p>
        </div>
        <FooterComp></FooterComp>
      </div>
    </ion-content>
  </ion-page>
</template>
<script >
import { IonPage, IonContent } from '@ionic/vue'
import HeaderComp from '@/components/layouts/HeaderComp'
import FooterComp from '@/components/layouts/FooterComp'
import AffiliateSearch from '@/views/content/AffiliateSearch'
import ShareComponent from '@/components/ShareComponent'
import RequestSharesComponent from '@/components/RequestSharesComponent'
import SharesPaymentStructureComponent from '@/components/SharesPaymentStructureComponent';
export default {
  components: {
    FooterComp, HeaderComp,
    ShareComponent,
    RequestSharesComponent,
    SharesPaymentStructureComponent,
    IonPage, IonContent
  },
  methods: {
    handleSearchComplete(results) {
      console.log(results);
      this.currentShares = results;
      if (results != null && results.length > 0) {
        this.showResults = true;
      }
    }
  },
  data: function () {
    return {
      currentShares: [],
      showResults: false
    }
  },
}
</script>
  
<style>
@media (prefers-color-scheme: dark){ 
    body{
      background-color: black;
    }
  }

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

}

.note-wrapper{
margin: 15px;

}

.note {
  font-size: 0.7em;
}

ul{
  padding-right: 30px;
}

@media screen and (min-width:800px) {
  .content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

  }

  .comp {
    width: 60%;
    display: flex;
  }

  .request {
    width: 50%;
  }

  .payment {
    width: 20%;
  }
}

.comp {
  /* width: 100%; */
  display: flex;
}

/* .request{
  width:100%;
}

.payment{
  width:100%;
} */</style>