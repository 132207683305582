<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="row">
        <HeaderComp currentPage="contact"></HeaderComp>

        <ContactContent class="text-black" />

        <FooterComp></FooterComp>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import HeaderComp from '@/components/layouts/HeaderComp'
import FooterComp from '@/components/layouts/FooterComp'
import ContactContent from '@/views/content/ContactContent'
import { IonContent, IonPage } from '@ionic/vue';
export default {
  components: { ContactContent, FooterComp, HeaderComp, IonContent, IonPage },
  data() {
    return {}
  },
}
</script>
