
import { toastController } from '@ionic/vue';
import { informationCircle } from 'ionicons/icons';

export async function toast(message:string, title = 'Info', duration = 3000){
    const toast = await toastController
        .create({
          header: title,
          message: message,
          icon: informationCircle,
          position: 'bottom',
          duration: duration
        //   buttons: [
        //     {
        //       side: 'start',
        //       icon: 'star',
        //       text: 'Favorite',
        //       handler: () => {
        //         console.log('Favorite clicked');
        //       }
        //     }, {
        //       text: 'Done',
        //       role: 'cancel',
        //       handler: () => {
        //         console.log('Cancel clicked');
        //       }
        //     }
        //   ]
        })
      await toast.present();
}