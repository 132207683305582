<template>
  <div class="col-md-12">
    <!-- ======= Hero Section ======= -->
    <div
      id="carouselExampleControls"
      class="carousel slide"
      data-ride="carousel"
      style="
        width: 100%;
        height: 100vh;
        background-color: rgba(63, 73, 83, 0.8);
        background-blend-mode: multiply;
        overflow: hidden;
        position: relative;
      "
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            class="d-block w-100"
            src="/assets/img/slide/slide-1.png"
            alt="First slide"
          />
          <div class="carousel-caption d-none d-md-block">
            <h1>Slide 1</h1>
            <p>text</p>
          </div>
        </div>
        <div class="carousel-item">
          <img
            class="d-block w-100"
            src="/assets/img/slide/slide-2.jpg"
            alt="Second slide"
          />
          <div class="carousel-caption d-none d-md-block">
            <h5>Slide 2</h5>
            <p>text</p>
          </div>
        </div>
        <div class="carousel-item">
          <img
            class="d-block w-100"
            src="/assets/img/slide/slide-3.jpg"
            alt="Third slide"
          />
          <div class="carousel-caption d-none d-md-block">
            <h5>Slide 3</h5>
            <p>text</p>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  <div class="col-md-12">
    <!-- ======= About Section ======= -->
    <section id="about" class="about text-black">
      <div class="container">
        <div class="row content">
          <div class="col-lg-6">
            <h2>Believer's Business Forum</h2>
            <p>
              BBF Africa is headquartered and registered in South Africa under
              the laws of South Africa as a Trust/PLC/NPC with its registered
              address in Pretoria. It is governed by a constitution and
              subscribes to international best practice and the legal framework
              of South Africa. The international Executives include highly
              seasoned professionals in the fields of Finance, Corporate
              Governance, Academia, Engineering, Legal, Development, ICT and the
              Humanities (Ref to Organogram). They are all bonded with the sole
              purpose of the vision, mission and stated objectives under the
              auspices of the corporate values and desire to synergize with
              like-minded professionals to impact our generation and the
              generations yet unborn.
            </p>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <h4>Our Objectives</h4>
            <ul>
              <li>
                <i class="ri-check-double-line"></i>
                Promote the interest and welfare of its members and chapters.
              </li>
              <li>
                <i class="ri-check-double-line"></i>
                Support, guide and assist its membership in business set-up,
                business re-engineering and international best practice in their
                business endeavours.
              </li>
              <li>
                <i class="ri-check-double-line"></i>
                Raise funds for infrastructure development projects in the
                various chapters and communities.
              </li>
              <li>
                <i class="ri-check-double-line"></i>
                Organize programs, seminars, workshops to promote networking,
                dissemination of knowledge and capacity building
              </li>
              <li>
                <i class="ri-check-double-line"></i>
                Network with the Africa’s Regional Economic Communities – SADC,
                ECOWAS, EAC, ECCAS etc
              </li>
              <li>
                <i class="ri-check-double-line"></i>
                Build network with African Continental Free Trade Area (AfCFTA)
                to promote businesses in Africa related to the vision and
                mission of BBF.
              </li>
            </ul>
            <p class="fst-italic">
              <!-- -->
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Section -->

    <!-- ======= Clients Section ======= -->
    <section class="text-dark">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2 class="text-center">Country Chapters</h2>
            <hr />
          </div>
          <div class="col-md-12">
            <p>
              BBF Africa envisages having chapters/affiliates in all African
              countries who subscribe to our mission, vision and values. The
              chapters shall be led by 7 to 9 executives and shall have the
              right to use the BBF brand as an affiliate or chapter within the
              ambit of the constitution and shall operate within their
              respective country’s legal regimes in pursuit of the stated
              objectives of BBF Africa. The chapters shall join the BBF Africa
              family of as a Non Profit Company (NPC), a Plc Ltd or as both
              depending on the legal regime allowed in the respective countries.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="clients" class="clients section-bg">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-za.png"
              class="img-fluid"
              alt=""
            />
          </div>

          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-gh.png"
              class="img-fluid"
              alt=""
            />
          </div>

          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-zim.png"
              class="img-fluid"
              alt=""
            />
          </div>

          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-ken.png"
              class="img-fluid"
              alt=""
            />
          </div>

          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-lib.png"
              class="img-fluid"
              alt=""
            />
          </div>

          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-bot.png"
              class="img-fluid"
              alt=""
            />
          </div>

          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-mal.png"
              class="img-fluid"
              alt=""
            />
          </div>

          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-ser.png"
              class="img-fluid"
              alt=""
            />
          </div>

          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-les.png"
              class="img-fluid"
              alt=""
            />
          </div>

          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-swa.png"
              class="img-fluid"
              alt=""
            />
          </div>

          <div
            class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/img/chapters/chapter-zam.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <!-- End Clients Section -->

    <!-- ======= Services Section ======= -->
    <section id="cta" class="cta">
      <div class="row">
        <div class="col-md-12">
          <div style="background: #eeeeee;">
            <div class="container">
              <!-- row -->
              <div class="row align-items-center g-0">
                <div class="col-xl-6 col-lg-6 col-md-12 text-black">
                  <!-- heading -->
                  <div>
                    <h1 class="display-4 fw-bold pe-lg-8">
                      Become a member of BBF Africa
                    </h1>
                    <!-- text -->
                    <p class="mb-4 lead">
                      Click below to become a member after successful
                      registration
                    </p>
                    <!-- btn -->
                    <a href="register" class="btn btn-dark">Register</a>
                  </div>
                </div>
                <!-- img -->
                <div
                  class="col-xl-6 col-lg-6 col-md-12 text-lg-end text-center pt-6"
                >
                  <!--                  <img src="../../../public/assets/img/73-736853_membership-png-members-png-transparent-png.png" alt="" style="filter: grayscale(100%);" class="img-fluid">-->
                  <img
                    src="../../../public/assets/img/hands-g886052324_1920.png"
                    alt=""
                    style="filter: grayscale(100%);"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data: function () {
    return {}
  },
}
</script>
