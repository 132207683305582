<template>
  <div class="col-md-12">
    <!-- ======= Header ======= -->
    <!--          <header id="header" class="fixed-top d-flex align-items-center bg-dark">-->
    <header id="header" class="d-flex align-items-center bg-dark">
      <div class="container d-flex align-items-center">
        <h1 class="logo me-auto">
          <a href="/home" class="text-white">
            BBF Africa &nbsp;
            <img src="../../../public/assets/bbf-logo.jpg" />
          </a>
        </h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

        <nav id="navbar" class="navbar text-warning">
          <ul>
            <li>
              <a
                href="/home"
                :class="{ active: (currentPage == null) | undefined }"
              >
                Home
              </a>
            </li>

            <li class="dropdown">
              <a
                style="cursor: pointer;"
                rel="nofollow"
                class="text-white"
                :class="{ active: currentPage == 'about' }"
              >
                <span :class="{ active: currentPage == 'about' }">About</span>
                <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li><a href="mission">Mission</a></li>
                <li><a href="vision">Vision</a></li>
                <li><a href="values">Values</a></li>
                <li><a href="team">Team</a></li>

                <!-- <li class="dropdown">
                  <a style="cursor: pointer;" rel="nofollow" class="text-white">
                    <span>Our Chapters</span>
                    <i class="bi bi-chevron-right"></i>
                  </a>
                  <ul>
                    <li><a href="chapter/za">South Africa</a></li>
                    <li><a href="chapter/gh">Ghana</a></li>
                    <li><a href="chapter/zim">Zimbabwe</a></li>
                    <li><a href="chapter/kn">Kenya</a></li>
                    <li><a href="chapter/le">Lesotho</a></li>
                    <li><a href="chapter/lib">Liberia</a></li>
                  </ul>
                </li> -->
              </ul>
            </li>
            <li class="dropdown">
              <a
                rel="nofollow"
                class="text-white"
                :class="{ active: currentPage == 'chapter' }"
              >
                <span :class="{ active: currentPage == 'chapter' }">
                  Our Chapters
                </span>
                <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li><a href="chapter/za">South Africa</a></li>
                <li><a href="chapter/gh">Ghana</a></li>
                <li><a href="chapter/zim">Zimbabwe</a></li>
                <li><a href="chapter/kn">Kenya</a></li>
                <li><a href="chapter/le">Lesotho</a></li>
                <li><a href="chapter/lib">Liberia</a></li>
                <li><a href="chapter/mal">Malawi</a></li>
              </ul>
            </li>
            <!--              <li><a href="#">Menu</a></li>-->
            <li>
              <a href="/contact" :class="{ active: currentPage == 'contact' }">
                Contact
              </a>
            </li>
            <li>
              <a
                href="/register"
                :class="{ active: currentPage == 'register' }"
              >
                Register
              </a>
            </li>
            <!-- <li>
              <a
                href="/shares"
                :class="{ active: currentPage == 'shares' }"
              >
                Shares
              </a>
            </li> -->
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
        <!-- .navbar -->
      </div>
    </header>
    <!-- End Header -->
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
export default {
  props: ['currentPage'],
  mounted() {
    //this.adminLoggedIn = ApiService.isLoggedIn
  },

  methods: {
    handleSignOut() {
      ApiService.signOut()
      this.$emit('signOut')
      this.adminLoggedIn = false
    },
  },
  data: function () {
    return {
      //adminLoggedIn: false,
    }
  },
}
</script>

<style scoped>
.activeDropdown {
  color: gold;
}
</style>
