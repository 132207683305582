export default class ShareModel{
    code!: string;
    fullName!: string;
    currency!: string;
    email!: string;
    purchaseDate!: string;
    quantity!: number;
    shareValue!: number;
    country!: string;

    constructor(){
        this.reset();
    }

    reset(){
        this.quantity = 1;
        this.shareValue = 5;
        this.currency = "USD";
        this.code = "";
        this.country = "";
        this.email = "",
        this.fullName = "";
        this.purchaseDate = new Date().toDateString()
    }
}