<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="row">

        <HeaderComp></HeaderComp>

        <landing-page-content class="text-black"/>

        <FooterComp></FooterComp>

      </div>
    </ion-content>
  </ion-page>
</template>
<script>
// import SliderPage from "@/views/SliderPage";
import HeaderComp from "@/components/layouts/HeaderComp";
import FooterComp from "@/components/layouts/FooterComp";
import LandingPageContent from "@/views/content/LandingPageContent";
import { IonContent, IonPage } from '@ionic/vue';
export default {
  components: {LandingPageContent, FooterComp, HeaderComp, IonContent, IonPage},
  // components: {SliderPage},
  data(){
    return {
    }
  },
}
</script>