<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="row">
        <HeaderComp currentPage="about"></HeaderComp>

        <MissionContent class="text-black" />

        <FooterComp></FooterComp>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import HeaderComp from '@/components/layouts/HeaderComp'
import FooterComp from '@/components/layouts/FooterComp'
import MissionContent from '@/views/content/MissionContent'
import { IonContent, IonPage } from '@ionic/vue';
export default {
  components: { MissionContent, FooterComp, HeaderComp, IonContent, IonPage },
  data() {
    return {}
  },
}
</script>
