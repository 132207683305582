<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="row">
        <HeaderComp currentPage="chapter"></HeaderComp>

        <ZimbabweChaperContent class="text-black" />

        <FooterComp></FooterComp>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import HeaderComp from '@/components/layouts/HeaderComp'
import FooterComp from '@/components/layouts/FooterComp'
import ZimbabweChaperContent from '@/views/content/chapters/ZimbabweChaperContent'
export default {
  components: { ZimbabweChaperContent, FooterComp, HeaderComp },

  data() {
    return {}
  },
}
</script>
