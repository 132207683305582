export default class RegistrationModel{
    id!: string;
    title!: string;
    gender!: string;
    name!: string;
    surname!: string;
    dob!: string;
    document!: string;
    citizenship!: string;
    residentCountry!: string;
    contact!: string;
    email!: string;
    affiliation!: string;
    profession!:string;
    yearsOfExperience!: string;
    reason!: string;
    employmentStatus!: string;
    businesses!: string[];
    skills!: string[];
    termsAccepted!: boolean;
    registrationDate!: string;

    constructor(title:string){
        this.title = title;        
        this.termsAccepted = false;
        this.businesses = new Array<string>();
        this.skills = new Array<string>();
        
    }

    fullName(){
        return this.name + " " + this.surname;
    }

    areTermsAccepted(){
        return this.termsAccepted;
    }

    isValidEmail(emailAddress:string){        
        return /^[^@]+@\w+(\.\w+)+\w$/.test(emailAddress);
    }

    isValid(){

        if(!this.title || !this.document || !this.name || !this.surname || !this.dob || !this.citizenship || !this.residentCountry
            || !this.contact || !this.isValidEmail(this.email) || !this.affiliation || !this.businesses || !this.yearsOfExperience ||
            !this.skills || !this.employmentStatus){
            return false;
        }
        return true;
    }
}