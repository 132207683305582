import  {database} from '../main';
import RegistrationModel from '@/models/RegistrationModel';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { sendEmail } from '@/helpers/mailer';
import ShareModel from '@/models/ShareModel';

const DatabaseNames = {
    SECTORS: 'Sectors',
    AFFILIATECOUNTRIES: 'Affiliate-Countries',
    COUNTRIES:'Countries',
    QUALIFICATIONS: 'Qualifications',
    REGISTRATIONS: 'Registrations',
    TITLES: 'Titles',
    PROFESSIONS: 'Professions',
    ADMINUSERS: "Administrators",
    SHARES: "Shares",
    SHARETYPES: "ShareType",
    COUNTRYSHARES: "AffiliateCountryShares"
}

const Status = {
    PENDING: 'Pending',
    APPROVED: 'Approved',
    DECLINED: 'Declined'
}

const LocalStorage = {
    USERID:  'UserId',
    ISADMIN: 'Administrator',
    ADMINCOUNTRY: ''
}

export default {
    async getCountries(){
        return database.collection(DatabaseNames.COUNTRIES).where('Active', "==", true).get();
        //return database.collection(DatabaseNames.COUNTRIES).where('Active', "==", true).orderBy('Name', 'asc').get();
     },
    async getAffiliationCountries(){
        return database.collection(DatabaseNames.AFFILIATECOUNTRIES).where('Active', "==", true).get();
        //return database.collection(DatabaseNames.COUNTRIES).where('Active', "==", true).orderBy('Name', 'asc').get();
    },
    async getTitles(){
        return database.collection(DatabaseNames.TITLES).where('Active', "==", true).get();
        //return database.collection(DatabaseNames.TITLES).where('Active', "==", true).orderBy('Name', 'asc').get();
    },
    async getProfessions(){
        return database.collection(DatabaseNames.PROFESSIONS).where('Active', "==", true).get();        
    },
    async getRegistrationsByStatus(status: string){
        return database.collection(DatabaseNames.REGISTRATIONS).where('status', "==", status).get();
        //return database.collection(DatabaseNames.REGISTRATIONS).where('status', "==", Status.PENDING).orderBy('Name', 'asc').get();
    },
    async getShareTypes(){
        return database.collection(DatabaseNames.SHARETYPES).where('active', "==", true).get();        
    },
    async getSharesByEmail(search: string){
        return database.collection(DatabaseNames.SHARES).where('email', "==", search).get();
        //return database.collection(DatabaseNames.REGISTRATIONS).where('status', "==", Status.PENDING).orderBy('Name', 'asc').get();
    },
    async getRegistrationsByNumber(search: string){
        return database.collection(DatabaseNames.REGISTRATIONS).where('number', "==", search).get();
        //return database.collection(DatabaseNames.REGISTRATIONS).where('status', "==", Status.PENDING).orderBy('Name', 'asc').get();
    },
    async getPendingRegistrations(){
        return database.collection(DatabaseNames.REGISTRATIONS).where('status', "==", Status.PENDING).get();
        //return database.collection(DatabaseNames.REGISTRATIONS).where('status', "==", Status.PENDING).orderBy('Name', 'asc').get();
    },
    async getApprovedRegistrations(){
        return database.collection(DatabaseNames.REGISTRATIONS).where('status', "==", Status.APPROVED).get();
        //return database.collection(DatabaseNames.REGISTRATIONS).where('status', "==", Status.PENDING).orderBy('Name', 'asc').get();
    },
    async updateAdminLogin(adminUserId: string){        

        database.doc(`${DatabaseNames.ADMINUSERS}/${adminUserId}`).update({         
            LastLogin: (new Date()).toDateString()            
        });
    },  
    async isAdminUser(){
        const stringUser = localStorage.getItem(LocalStorage.USERID); 
         database.collection(DatabaseNames.ADMINUSERS).where('ID', "==", stringUser).get()
        .then((result)=>{
            if(result != null){
                
                //We are only expecting one result here
                result.docs.forEach(x=>{                    
                    const adminUser = x.data();
                    localStorage.setItem(LocalStorage.ADMINCOUNTRY, adminUser.Country)
                    localStorage.setItem(LocalStorage.ISADMIN, 'true');
                    this.updateAdminLogin(adminUser.ID);

                });
                
            }else{
                localStorage.setItem(LocalStorage.ADMINCOUNTRY, '');
                localStorage.setItem(LocalStorage.ISADMIN, '');
            }
        });
        
    },
    addCountry(name: string, prefix: string){
        database.collection(DatabaseNames.COUNTRIES).add({
            Active: true,
            Name: name,
            Prefix: prefix
        })
    },
    autoLogin(){        
        const email = "visitor@user.com";
        const password = "user100";
        localStorage.setItem(LocalStorage.ISADMIN, '');

        const auth = getAuth();
        signInWithEmailAndPassword (auth, email, password);
        
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const uid = user.uid;
              localStorage.setItem(LocalStorage.USERID, uid);
              // ...
            } else {
              // User is signed out
              localStorage.setItem(LocalStorage.USERID, '');
            }
          });
        
    },
    login(email: string, password: string){        
        
        const auth = getAuth();
        signInWithEmailAndPassword (auth, email, password)
        
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const uid = user.uid;
              localStorage.setItem(LocalStorage.USERID, uid);
              this.isAdminUser();
              // ...
            } else {
              // User is signed out
              localStorage.setItem(LocalStorage.USERID, '');
              localStorage.setItem(LocalStorage.ISADMIN, '');
            }
        });
        
    },
    signOut(){
        const auth = getAuth();
        signOut(auth);
        localStorage.setItem(LocalStorage.USERID, '');
        localStorage.setItem(LocalStorage.ISADMIN, 'false');

        this.autoLogin();
    },
    get isLoggedIn(): boolean {
        const stringUser = localStorage.getItem(LocalStorage.USERID)         
        return (stringUser as string).length > 0 ? true : false
    },
    get isAuthorized(): boolean{
        const admin = localStorage.getItem(LocalStorage.ISADMIN) 
        return (admin as string).length > 0 ? true : false;
    },
    get defaultAdminCountry(): string{
        const country = localStorage.getItem(LocalStorage.ADMINCOUNTRY) 
        return (country as string);
    },

    async requestShares(shareData: ShareModel){
        const docRef = database.collection('_').doc();
        database.doc(`${DatabaseNames.SHARES}/${docRef.id}`).set({
            code: shareData.code,
            currency: shareData.currency,
            email: shareData.email,
            fullName: shareData.fullName,
            purchaseDate: shareData.purchaseDate,
            quantity: shareData.quantity,
            shareValue: shareData.shareValue
        });
    },
    
    async register(registrationData: RegistrationModel){
        
        const docRef = database.collection('_').doc();
        const userId = localStorage.getItem(LocalStorage.USERID);
        database.doc(`${DatabaseNames.REGISTRATIONS}/${docRef.id}`).set({
            id: docRef.id,
            title: registrationData.title,
            name: registrationData.name,
            surname: registrationData.surname,
            dob: registrationData.dob,
            document: registrationData.document,
            citizenship: registrationData.citizenship,
            residentCountry: registrationData.residentCountry,
            contact: registrationData.contact,
            email: registrationData.email,
            affiliation: registrationData.affiliation,
            profession: registrationData.profession,
            employmentStatus: registrationData.employmentStatus,
            businesses: registrationData.businesses.join(','),
            reason: registrationData.reason,
            yearsOfExperience: registrationData.yearsOfExperience,
            skills: registrationData.skills.join(','),
            termsAccepted: registrationData.termsAccepted,
            status: Status.PENDING,
            registrationDate: (new Date()).toDateString(),
            updatedDate: (new Date()).toDateString(),
            createdBy: userId,
            updatedBy: ''
        }).then(()=>{
            sendEmail(registrationData);
        });
    },
    async updateRegistration(registrationData: any, newStatus: string, notes = ""){       
        const userId = localStorage.getItem(LocalStorage.USERID) as string;
        
        database.doc(`${DatabaseNames.REGISTRATIONS}/${registrationData.id}`).update({
            status: newStatus,
            updatedDate: (new Date()).toDateString(),
            updatedBy: userId,
            note: notes
        });
    },    

}