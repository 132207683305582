import RegistrationModel from '@/models/RegistrationModel';
import emailjs from 'emailjs-com';

export function sendEmail( applicant: RegistrationModel) {    

    const templateParams =  {
        affiliation: applicant.affiliation,        
        message:`${applicant.name} ${applicant.surname} - ${applicant.email} - ${applicant.contact}`,
        manage_url:"https://thebbfafrica.co.za/manage"
    }

    emailjs.send('service_bbf_admin', 'template_7ozeyjq', templateParams, 'mKzvv8lHOad0DGvVy');
    //   .then((result) => {
    //      console.log(result); 
    //   }, (error) => {
    //       console.log(error)
    // });
}

export function sendStatusEmail( applicant: RegistrationModel, status: string) {    

    const templateParams =  {
        company: "BBF-Africa",   
        applicant: applicant.name,     
        recipient: applicant.email,
        message:`The status of your registration has been updated to - ${status}`
    }

    emailjs.send('service_bbf_admin', 'template_3nrpu0e', templateParams, 'mKzvv8lHOad0DGvVy');
    //   .then((result) => {
    //      console.log(result); 
    //   }, (error) => {
    //       console.log(error)
    // });
}
